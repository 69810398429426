import React, { Component } from "react";
import { Link } from "gatsby";

import Layout from "../layouts/en";
import SEO from "../components/seo";
import FormForgetPassword from "../services/ForgetPassword";
import LangData from "../data/static-pages/pages/forget-password/en";

export default class ForgotPasswordPage extends Component {
  render() {
    return (
      <Layout location={this.props.location} footer_margin={true}>
        <SEO
          title="Forget Password "
          keyword="Forget Password"
          decription="Forget Password"
        />

        <FormForgetPassword langData={LangData} props={this.props} />
      </Layout>
    );
  }
}
